<template>
  <fd-form
    class="property-change-price card p-4"
    @submit="$emit('update', { id: property.id, priceData: priceData })"
    @error="validateError"
  >
    <div class="ref-code">{{ property.referenceCode }}</div>
    <fd-form-section title="Change Price">
      <fd-input
        v-if="
          property.purpose == purpose.SALE ||
          property.purpose == purpose.SALE_AND_RENT ||
          property.purpose == purpose.AUCTION
        "
        v-model="priceData.salePrice"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Sale Price"
        name="salePrice"
        type="text"
        :validators="[validators.required, validators.price]"
        @input="
          () => {
            priceData.salePricePerUnit = calculateUnitPrice(
              priceData.salePrice,
              areaValue
            );
          }
        "
      >
      </fd-input>
      <fd-input
        v-if="
          property.purpose == purpose.SALE ||
          property.purpose == purpose.SALE_AND_RENT ||
          property.purpose == purpose.AUCTION
        "
        v-model="priceData.salePricePerUnit"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Sale Price Per Unit"
        name="salePricePerUnit"
        type="text"
        :validators="[validators.required, validators.price]"
        readonly
      >
      </fd-input>
      <fd-input
        v-if="
          property.purpose == purpose.RENT ||
          property.purpose == purpose.SALE_AND_RENT
        "
        v-model="priceData.rentPrice"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Rent Price"
        name="rentPrice"
        type="text"
        :validators="[validators.required, validators.price]"
        @input="
          () => {
            priceData.rentPricePerUnit = calculateUnitPrice(
              priceData.rentPrice,
              areaValue
            );
          }
        "
      >
      </fd-input>
      <fd-input
        v-if="
          property.purpose == purpose.RENT ||
          property.purpose == purpose.SALE_AND_RENT
        "
        v-model="priceData.rentPricePerUnit"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Rent Price Per Unit"
        name="rentPricePerUnit"
        type="text"
        :validators="[validators.required, validators.price]"
        readonly
      >
      </fd-input>
      <fd-select
        v-model="pricePerUnitOrigin"
        class="col-12 px-1 mb-2"
        label="Unit Price Calculate With"
        name="unitPriceType"
        :options="unitPriceCalculateOptions"
        :optionValue="
          (option) => {
            return option;
          }
        "
        :optionLabel="
          (option) => {
            return option;
          }
        "
        :validators="[validators.required]"
        @change="
          (val) => {
            if (val == 'Built Up') {
              areaValue = property.builtUpArea;
            } else if (val == 'Land') {
              areaValue = property.landArea;
            }
            priceData.salePricePerUnit = calculateUnitPrice(
              priceData.salePrice,
              areaValue
            );
            priceData.rentPricePerUnit = calculateUnitPrice(
              priceData.rentPrice,
              areaValue
            );
          }
        "
      >
      </fd-select>
    </fd-form-section>
    <div class="text-right">
      <button type="button" class="btn mr-1" @click="$emit('close')">
        Cancel
      </button>
      <button class="btn main">Update</button>
    </div>
  </fd-form>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import purposeEnum from "@/enums/purpose";

export default {
  components: {},
  mixins: [],
  props: {
    property: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      unitPriceCalculateOptions: ["Built Up", "Land"],
      pricePerUnitOrigin: "",
      areaValue: 0,

      priceData: {
        salePrice: "",
        salePricePerUnit: "",
        rentPrice: "",
        rentPricePerUnit: ""
      },

      validators: {
        required: required,
        price: price
      },

      purpose: purposeEnum
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initPriceData();
  },
  methods: {
    initPriceData() {
      this.priceData.salePricePerUnit = this.property.salePricePerUnit;
      this.priceData.salePrice = this.property.salePrice;
      this.priceData.rentPricePerUnit = this.property.rentPricePerUnit;
      this.priceData.rentPrice = this.property.rentPrice;
    },
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    calculateUnitPrice(price, unit) {
      return (
        Math.round((parseFloat(price) / parseFloat(unit)) * 100) / 100
      ).toFixed(2);
    }
  }
};
</script>

<style lang="scss">
.ref-code {
  margin-bottom: 12px;
  border: solid 1px $color-secondary;
  width: fit-content;
  border-radius: 5px;
  padding: 6px 15px;
  color: $color-secondary;
}
</style>
